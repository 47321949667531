import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "../../SCSS/landing.css";
import "../../SCSS/fonts.css";

import immersify_logo from "../../images/immersify_logo.svg";

import { Students } from "../Students/Students";
import { Educators } from "../Educators/Educators";
// import { Professionals } from "../Professionals/Professionals";

export const Header = (props) => {
  const link = props.link;
  const setPageToShow = props.setPageToShow;
  const setStudentTabStyle = props.setStudentTabStyle;

  const [studentButtonStyle, setStudentButtonStyle] = useState("");
  const [educatorsButtonStyle, setEducatorsButtonStyle] = useState("");
  const [, setProfessionalButtonStyle] = useState("");
  const [aboutUsButtonStyle, setAboutUsButtonStyle] = useState("");
  function handleStudentsClicked() {
    setPageToShow(<Students />);
    setStudentButtonStyle("active");
    setStudentTabStyle("");
    setProfessionalButtonStyle("");
    setEducatorsButtonStyle("");
    setAboutUsButtonStyle("");
  }

  function handleEducatorsClicked() {
    setPageToShow(<Educators />);
    setEducatorsButtonStyle("active");
    setStudentButtonStyle("");
    setStudentTabStyle("left50");
    setProfessionalButtonStyle("");
    setAboutUsButtonStyle("");
  }

  // function handleProfessionalsClicked() {
  //   setPageToShow(<Professionals />);
  //   setProfessionalButtonStyle("active");
  //   setStudentTabStyle("left100");
  //   setEducatorsButtonStyle("");
  //   setStudentButtonStyle("");
  //   setAboutUsButtonStyle("");
  // }
  function handleAboutUsClicked() {
    setAboutUsButtonStyle("active");
    setStudentButtonStyle("");
    setEducatorsButtonStyle("");
    setProfessionalButtonStyle("");
  }


  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    if (link !== true) {
      setStudentButtonStyle("active");
    }
    if (window.location.href.includes("aboutus")) {
      setAboutUsButtonStyle("active");
      setStudentButtonStyle("");
      setEducatorsButtonStyle("");
      setProfessionalButtonStyle("");
    }
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 80);
    });
  }, []);

  const topScrollLimit = 50;

  function handleBlog() {
    window.location.href = process.env.REACT_APP_WORDPRESS_URL + "/blog";
  }

  function handleAboutus() {
    window.location.href = process.env.REACT_APP_CLIENT_URL + "/aboutus";
    handleAboutUsClicked();
  }

  function handleStudents(scrollTo) {
    if (link === true) {
      window.location.href =
        process.env.REACT_APP_CLIENT_URL + "/Landing/Students";
      return;
    }
    if (window.scrollY < topScrollLimit) {
      window.scrollTo({ top: scrollTo, behavior: "smooth" });
    }
    handleStudentsClicked();
  }

  function handleEducators(scrollTo) {
    if (link === true) {
      window.location.href =
        process.env.REACT_APP_CLIENT_URL + "/Landing/Institutions";
      return;
    }
    if (window.scrollY < topScrollLimit) {
      window.scrollTo({ top: scrollTo, behavior: "smooth" });
    }
    handleEducatorsClicked();
  }

  // function handleProfessionals(scrollTo) {
  //   if (link === true) {
  //     window.location.href =
  //       process.env.REACT_APP_CLIENT_URL + "/Landing/Professionals";
  //     return;
  //   }
  //   if (window.scrollY < topScrollLimit) {
  //     window.scrollTo({ top: scrollTo, behavior: "smooth" });
  //   }
  //   handleProfessionalsClicked();
  // }

  function handleLogoClicked() {
    window.location.href = process.env.REACT_APP_CLIENT_URL;
  }

  return (
    <>
      <section className={scroll ? "nav_wrap fixed_nav" : "nav_wrap"}>
        <div className="container main_nav">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                  <a className="navbar-brand" href="#">
                    <img
                      src={immersify_logo}
                      alt="Logo"
                      onClick={handleLogoClicked}
                    />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo03"
                    aria-controls="navbarTogglerDemo03"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse"
                    id="navbarTogglerDemo03"
                  >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <a
                          className={"nav-link " + educatorsButtonStyle}
                          onClick={() => handleEducators(500)}
                        >
                          Institutions
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={"nav-link " + studentButtonStyle}
                          aria-current="page"
                          onClick={() => handleStudents(500)}
                        >
                          Students
                        </a>
                      </li>
                      {/*<li className="nav-item">*/}
                      {/*  <a*/}
                      {/*    className={"nav-link " + professionalButtonStyle}*/}
                      {/*    onClick={() => handleProfessionals(500)}*/}
                      {/*  >*/}
                      {/*    Professionals*/}
                      {/*  </a>*/}
                      {/*</li>*/}

                      <li className="nav-item">
                        <a
                          className={"nav-link " + aboutUsButtonStyle}
                          aria-current="page"
                          onClick={() => handleAboutus()}
                        >
                          About us
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className={"nav-link"}
                          aria-current="page"
                          onClick={() => handleBlog()}
                        >
                          Blog
                        </a>
                      </li>

                      {/* <li className="nav-item">
                            <a className="nav-link" href="#">Blog</a>
                            </li> */}

                      <li className="nav-item free_trial">
                        <a
                          className="nav-link"
                          href={
                            process.env.REACT_APP_CLIENT_URL + "/Profile"
                          }
                        >
                          Go to Immersify
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Header.propTypes = {
  setPageToShow: PropTypes.func,
  setStudentTabStyle: PropTypes.func,
  link: PropTypes.bool,
};
