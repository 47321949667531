import React from "react";
import PropTypes from "prop-types";

import "../../SCSS/landing.css"
import "../../SCSS/fonts.css"

import immersify_logo from "../../images/immersify_logo.svg";

import {Students} from "../Students/Students";
import {Educators} from "../Educators/Educators";
// import { Professionals } from "../Professionals/Professionals";

export const Footer = (props) => {

  const link = props.link
  const setPageToShow = props.setPageToShow;
  const setStudentButtonStyle = props.setStudentButtonStyle;
  const setEducatorsButtonStyle = props.setEducatorsButtonStyle;
  const setProfessionalButtonStyle = props.setProfessionalButtonStyle;
  const setStudentTabStyle = props.setStudentTabStyle;

  function handleStudentsClicked() {
    setPageToShow(<Students/>);
    setStudentButtonStyle("active");
    setStudentTabStyle("");
    setProfessionalButtonStyle("");
    setEducatorsButtonStyle("");
  }

  function handleEducatorsClicked() {
    setPageToShow(<Educators/>);
    setEducatorsButtonStyle("active");
    setStudentButtonStyle("");
    setStudentTabStyle("left50");
    setProfessionalButtonStyle("");
  }

  // function handleProfessionalsClicked() {
  //     setPageToShow(<Professionals />);
  //     setProfessionalButtonStyle("active");
  //     setStudentTabStyle("left100");
  //     setEducatorsButtonStyle("");
  //     setStudentButtonStyle("");
  // }
  //
  function handleTryWebPlatform() {
    window.location.href = process.env.REACT_APP_CLIENT_URL + "/Profile";
  }

  function appSecurityPolicy() {
    window.location.href = process.env.REACT_APP_CLIENT_URL + "/application-privacy-policy";
  }

  function webSecurityPolicy() {
    window.location.href = process.env.REACT_APP_CLIENT_URL + "/website-privacy-policy";
  }

  function termsofservice() {
    window.location.href = process.env.REACT_APP_CLIENT_URL + "/terms-of-service";
  }

  function handleDownloadApp() {
    window.location.href =
      "https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%5D";
  }

  function handleInstagram() {
    window.location.href =
      "https://www.instagram.com/immersify_education/?utm_source=IE%20INSTA&utm_medium=IE%20INSTA&utm_campaign=IE%20INSTA";
  }

  function handleFacebook() {
    window.location.href =
      "https://www.facebook.com/ImmersifyEducation?utm_source=FACEBOOK&utm_medium=FACEBOOK&utm_campaign=FACEBOOK";
  }

  function handleLinkedin() {
    window.location.href =
      "https://www.linkedin.com/company/immersify-education";
  }

  function handleStudentsfooter() {
    if (link === true) {
      window.location.href =
        process.env.REACT_APP_CLIENT_URL + "/Landing";
      return;
    }
    window.scrollTo({top: scrollTo, behavior: "smooth"});
    handleStudentsClicked();
  }

  function handleEducatorsfooter() {
    if (link === true) {
      window.location.href =
        process.env.REACT_APP_CLIENT_URL + "/Landing";
      return;
    }
    window.scrollTo({top: scrollTo, behavior: "smooth"});
    handleEducatorsClicked();
  }

  // function handleProfessionalsfooter() {
  //     if (link === true) {
  //         window.location.href =
  //             process.env.REACT_APP_CLIENT_URL + "/Landing";
  //         return;
  //     }
  //     window.scrollTo({ top: scrollTo, behavior: "smooth" });
  //     handleProfessionalsClicked();
  // }

  return (
    <>
      <footer className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <img
                    src={immersify_logo}
                    alt="Logo Immersify"
                  />
                  <div className="ft_address">
                    <i className="fa fa-map-marker"></i>
                    <div>
                      <p>
                        Manchester, United Kingdom
                      </p>
                      <p>
                        New York City, New York, USA
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 ft_center">
                  <div className="ft_block">
                    <h4>Quick links</h4>
                    <ul>
                      <li>
                        <button
                          onClick={() =>
                            handleStudentsfooter()
                          }
                        >
                          Students
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() =>
                            handleEducatorsfooter()
                          }
                        >
                          Institutions
                        </button>
                      </li>
                      {/*<li>*/}
                      {/*    <button*/}
                      {/*        onClick={() =>*/}
                      {/*            handleProfessionalsfooter()*/}
                      {/*        }*/}
                      {/*    >*/}
                      {/*        Professionals*/}
                      {/*    </button>*/}
                      {/*</li>*/}
                      <li>
                        <button
                          onClick={() =>
                            appSecurityPolicy()
                          }
                        >
                          Application privacy policy
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={() =>
                            webSecurityPolicy()
                          }
                        >
                          Website security policy
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={() =>
                            termsofservice()
                          }
                        >
                          Terms of service
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3 ft_center">
                  <div className="ft_block">
                    <h4>How it works</h4>
                    <ul>
                      <li>
                        <button
                          onClick={
                            handleTryWebPlatform
                          }
                        >
                          Web platform
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={handleDownloadApp}
                        >
                          Download app
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-3 ft_center">
                  <div className="ft_block social_m">
                    <h4>Social</h4>
                    <ul>
                      <li>
                        <button
                          onClick={handleInstagram}
                        >
                          <i className="fa fa-instagram"></i>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={handleFacebook}
                        >
                          <i className="fa fa-facebook"></i>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={handleLinkedin}
                        >
                          <i className="fa fa-linkedin"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  setPageToShow: PropTypes.func,
  setStudentButtonStyle: PropTypes.func,
  setEducatorsButtonStyle: PropTypes.func,
  setProfessionalButtonStyle: PropTypes.func,
  setStudentTabStyle: PropTypes.func,
  link: PropTypes.bool,
};
