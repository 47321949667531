import React from "react";
import {CheckIfLoggedIn} from "../../../../../Utils/CheckIfLoggedIn";

export const ReportsDoc = () => {
  const sessionTicket = CheckIfLoggedIn(true);

  const playfabId = localStorage.getItem("playfabID");

  if (!sessionTicket || !playfabId) {
    console.log("Session ID: " + sessionTicket);
    console.log("PlayFab ID: " + playfabId);
    console.error("Not logged in??");
    window.location.href = "/";
  }

  const urlParams = new URLSearchParams();
  urlParams.append("PlayFabId", playfabId);
  urlParams.append("SessionTicket", sessionTicket);

    return (
        <>
            <div className="profile_edit_sec qr_c">
                <h3>Reports</h3>
                <p>Download and retrieve detailed reports.</p>
                <div className="reports_sec">
                    <iframe src={"https://im-sales-portal-8acc2a83f73b.herokuapp.com/reporting/UserReports.html?" + urlParams.toString()} />

                </div>
                
            </div>
        </>
    );
};

